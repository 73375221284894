import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AdminPage from './AdminHome';
import StudentPage from './StudentPage';

const Landing_Page = () => {
  const history = useHistory();
  const [role, editRole] = useState('NoData');
  const [pageLoading, editPageLoading] = useState(true);
  useEffect(() => {
    const userRole = localStorage.role;
    console.log(userRole);
    if (userRole) {
      editRole(userRole);
      console.log(role);
    } else {
      history.push('/');
    }
  }, [role]);

  return (
    <div>
      {(() => {
        console.log(role == '"ADMIN"', role);
        if (role != 'NoData') {
          return role === '"ADMIN"' ? (
            <AdminPage></AdminPage>
          ) : (
            <StudentPage></StudentPage>
          );
        } else {
          return '';
        }
      })()}
    </div>
  );
};

export default Landing_Page;
