import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '../api/customAxios';
import serverConst from '../const';
import * as XLSX from 'xlsx';

import LoadingEliment from '../components/loadingPage';
import TestCard from '../components/TestAdminCard';
import UserCard from '../components/UserAdminCard';
import {
  FaEdit,
  FaUserPlus,
  FaSave,
  FaWindowClose,
  FaCloudUploadAlt,
} from 'react-icons/fa';

const MainContainer = styled.div`
  min-height: 100vh;
  background-color: #ff6363;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Container = styled.div`
  padding: 1rem;
  height: 8rem;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  padding-left: 2rem;
`;

const NumberData = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: #ff6363;
`;

const MainDataContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - (10rem));
`;

const MainDataSubContainer = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  width: 49.5%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const MainHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const MainTestsListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const Select = styled.select`
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
`;

const SelectEditBulk = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const UserMainBtnContainer = styled.div`
  display: flex;
  width: 10rem;
  justify-content: space-evenly;
`;

const EditScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #0006;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditScreenMainContainer = styled.div`
  width: 45%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 10rem;
  padding: 1rem;
  text-align: center;
`;

const EditScreenBtnContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const EditScreenBtnContainerV2 = styled.div`
  display: flex;
  justify-content: center;
`;

const EditScreenExitBtn = styled.button`
  padding: 1rem;
  width: 48%;
  background-color: #ff6363;
  color: #fff;
  border: 1px solid #ff6363;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #ff6363;
  }
`;

const EditScreenSaveBtn = styled.button`
  padding: 1rem;
  width: 48%;
  background-color: #ffbd69;
  color: #fff;
  border: 1px solid #ffbd69;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #ffbd69;
  }
`;

const EditScreenInpuContainer = styled.div`
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const AddUserScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #0006;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddUserScreenMainContainer = styled.div`
  width: 45%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 10rem;
  padding: 1rem;
  text-align: center;
`;

const AddBulkUsersUploadScreenContainer = styled.div`
  width: 100%;
  min-height: 10rem;
`;

const TemplateDownloadContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const CsvFileUpload = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #ff6363;
  border: 1px solid #ff6363;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #ff6363;
  }
`;

const UsersAddConformationContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 10rem;
  padding: 1rem;
  text-align: center;
`;

const UserAddCongormation = styled.div`
  overflow-y: scroll;
  max-height: 60vh;
`;

const AdminPage = () => {
  const history = useHistory();
  const [pageLoading, editPageLoading] = useState(true);
  const [dashData, editDashData] = useState({
    currentBatch: '',
    totalUsers: '',
    usersInCurrentBatch: '',
    tests: [],
    users: [],
    batchList: [],
  });

  const [showEditScreen, editShowEditScreen] = useState(false);

  const [loadingMessage, editLoadingMessage] = useState(
    'Loading the page, Please wait.',
  );
  const [usersToShow, editUsersToShow] = useState([]);

  const [filterVal, editFilterVal] = useState('ALL');
  const [selectionMode, editSelectionMode] = useState(false);
  const [selectedUsers, editSelectedUsers] = useState([]);

  const [bulkUserState, editBulkUserState] = useState('');
  const [bulkUserBatch, editBulkUserBatch] = useState('Select Batch');

  const [showSingleUserEditScreen, editShowSingleUserEditScreen] =
    useState(false);

  const [singleUserBatch, editSingleUserBatch] = useState('');
  const [singleUserState, editSingleUserState] = useState('');
  const [singleUserName, editSingleUserName] = useState('');
  const [singleUserEmail, editSingleUserEmail] = useState('');
  const [singleUserPassword, editSingleUserPassword] = useState('');
  const [singleUserID, editSingleUserID] = useState('');

  const [showAddUsersScreen, editShowAddUsersScreen] = useState(false);

  const [showAddUsersConformationScreen, editShowAddUsersConformationScreen] =
    useState(false);

  const [usersToAdd, editUsersToAdd] = useState([]);

  const [showAddUserErrScreen, editShowAddUserErrScreen] = useState(false);

  const [errUsersList, editErrUsersList] = useState([]);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    loadDashData();
  }, []);

  const loadDashData = () => {
    console.log('get Admin Data');
    axios
      .get(`${serverConst.IP}/admin/getDashData`)
      .then((res) => {
        console.log(res.data.data);
        const newDashData = {
          currentBatch: '',
          totalUsers: 0,
          usersInCurrentBatch: 0,
          tests: [],
          users: [],
          batchList: [],
        };

        newDashData.currentBatch = res.data.data.currentBatch;
        newDashData.totalUsers = res.data.data.userData.length;
        newDashData.batchList = res.data.data.batchList;
        res.data.data.userData.forEach((user) => {
          if (user.batchNo == newDashData.currentBatch) {
            newDashData.usersInCurrentBatch += 1;
          }
        });
        newDashData.tests = res.data.data.testsData;
        newDashData.users = res.data.data.userData;
        editDashData(newDashData);
        if (filterVal == 'ALL') {
          editUsersToShow(newDashData.users);
        } else {
          filterUsers(filterVal);
        }
        setTimeout(() => {
          editPageLoading(false);
        }, 400);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const editTest = (testUID) => {
    editPageLoading(true);
    editLoadingMessage('Editing the Test State. Please wait.');
    console.log('test');
    const testIndex = dashData.tests.findIndex((test) => test.UID == testUID);
    // console.log(testIndex);
    // console.log(dashData.tests[testIndex].state);
    let dataToSend = {
      editData: {
        status:
          dashData.tests[testIndex].state == 'ACTIVE' ? 'NOT-ACTIVE' : 'ACTIVE',
      },
      testUID: testUID,
    };
    axios
      .post(`${serverConst.IP}/admin/editTestData`, dataToSend)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      editPageLoading(false);
      editLoadingMessage('Loading the Page, Please wait.');
      console.log('test01');
      loadDashData();
    }, 1000);
  };

  const filterUsers = (val) => {
    console.log(val);

    let tempUsers = [];
    if (val !== 'ALL') {
      dashData.users.forEach((user) => {
        if (user.batchNo == val) {
          tempUsers.push(user);
        }
      });
    } else {
      tempUsers = dashData.users;
    }
    console.log('DashData users');
    console.log(dashData.users);
    editUsersToShow(tempUsers);
    editFilterVal(val);
  };

  const batchEdit = () => {
    editSelectionMode(true);
  };

  const editSelection = () => {
    console.log(selectedUsers);
    editShowEditScreen(true);
  };

  const closeSelection = () => {
    editSelectedUsers([]);
    loadDashData();
    editSelectionMode(false);
  };

  const editUserSelection = (userID, action) => {
    let tempData = selectedUsers;
    if (action == 'ADD') {
      tempData.push(userID);
      console.log(tempData);
    } else {
      tempData = tempData.filter((v) => v != userID);
      console.log(tempData);
    }
    editSelectedUsers(tempData);
    console.log('tt');
    console.log(selectedUsers);
  };

  const closeBulkEditScreen = () => {
    editShowEditScreen(false);
    editBulkUserBatch('');
    editBulkUserState('');
  };

  const saveBulkEdit = () => {
    console.log('request will be sent to the server to save the changes.');
    let dataToSend = {
      editData: {
        status: bulkUserState,
        batchNo: bulkUserBatch,
      },
      usersToEdit: selectedUsers,
    };

    editPageLoading(true);
    editLoadingMessage('Editing users');

    if (selectedUsers.length > 0) {
      axios
        .post(`${serverConst.IP}/admin/editUserBulk`, dataToSend)
        .then((res) => {
          setTimeout(() => {
            alert('user Data has been edited.');
            editPageLoading(false);
            editLoadingMessage('Loading Data, Please Wait');
            closeBulkEditScreen();
            closeSelection();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('Please select users to edit.');
    }
  };

  const editSingleUser = (userID) => {
    editShowSingleUserEditScreen(true);
    let currentUser = dashData.users.filter((user) => user.id == userID)[0];
    editSingleUserName(currentUser.name);
    editSingleUserEmail(currentUser.email);
    editSingleUserPassword(currentUser.password);
    editSingleUserBatch(currentUser.batchNo);
    editSingleUserState(currentUser.status);
    editSingleUserID(userID);
  };

  const closeEditSingleUser = () => {
    editShowSingleUserEditScreen(false);
    editSingleUserName('');
    editSingleUserEmail('');
    editSingleUserPassword('');
    editSingleUserBatch('');
    editSingleUserState('');
    editSingleUserID('');
  };

  const saveEditSingleUser = () => {
    const dataToSend = {
      userID: singleUserID,
      editData: {
        name: singleUserName,
        email: singleUserEmail,
        password: singleUserPassword,
        batchNo: singleUserBatch,
        status: singleUserState,
      },
    };

    editLoadingMessage('editing single user');
    editPageLoading(true);
    axios
      .post(`${serverConst.IP}/admin/editUser`, dataToSend)
      .then((res) => {
        setTimeout(() => {
          alert('user Data has been edited');
          editLoadingMessage('Loading Data, Please Wait.');
          editPageLoading(false);
          closeEditSingleUser();
          loadDashData();
        }, 1000);
        console.log(res);
      })
      .catch((err) => {
        alert('There was an error with the request \n' + err.message);
        console.log(err.message);
      });
  };

  const bulkAddUsers = () => {
    editShowAddUsersScreen(true);
    editShowAddUsersConformationScreen(false);
  };

  const closeAddUserScreen = () => {
    editUsersToAdd([]);
    editShowAddUsersConformationScreen(false);
    editShowAddUsersScreen(false);
  };

  const saveBulkUsersData = () => {
    if (usersToAdd.length < 0) {
      alert('Please Upload file before saving data');
      bulkAddUsers();
    } else {
      let dataToSend = {
        userData: usersToAdd,
      };
      editLoadingMessage('Adding users to now batch. Please wait...');
      editPageLoading(true);
      axios
        .post(`${serverConst.IP}/admin/createBatch`, dataToSend)
        .then((res) => {
          console.log(res.data);
          if (res.data.data.errUsers && res.data.data.errUsers.length > 0) {
            setTimeout(() => {
              editShowAddUserErrScreen(true);
              editErrUsersList(res.data.data.errUsers);
              editLoadingMessage('Loading Page Data Please wait.');
              editPageLoading(false);
            }, 1000);
          } else {
            setTimeout(() => {
              alert('All the users have been added without any errors.');
              closeAddUserScreen();
              editLoadingMessage('Loading Page Data Please wait.');
              editPageLoading(false);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const closeUserErrScreen = () => {
    editShowAddUserErrScreen(false);
    editErrUsersList([]);
    closeAddUserScreen();
  };

  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    return { data: list, keys: columns };
  };

  const svgTemplateUpload = (e) => {
    // console.log(csvJSON(e.target.files[0]));
    // console.log(e.target.files);
    let UploadData;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      UploadData = processData(data);
      if (
        UploadData.keys[0].name == 'name' &&
        UploadData.keys[0].selector == 'name' &&
        UploadData.keys[1].name == 'email' &&
        UploadData.keys[1].selector == 'email' &&
        UploadData.keys[2].name == 'password' &&
        UploadData.keys[2].selector == 'password'
      ) {
        editShowAddUsersConformationScreen(true);
        editUsersToAdd(UploadData.data);
      } else {
        alert(
          'the uploaded file is not valid. \n\n this might be because the file format is wrong \n Please Try Again',
        );
      }
      // console.log(UploadData);
    };
    reader.readAsBinaryString(file);
  };

  // console.log(dashData);
  return (
    <MainContainer>
      <div>
        <Container>
          <div>
            <h1>Welcome Admin</h1>
          </div>
          <div>
            <b>
              Total Users: <NumberData>{dashData.totalUsers}</NumberData>
            </b>
            <br />
            <b>
              Current Batch: <NumberData>{dashData.currentBatch}</NumberData>
            </b>
            <br />
            <b>
              Current Batch Users:{' '}
              <NumberData>{dashData.usersInCurrentBatch}</NumberData>
            </b>
          </div>
        </Container>
        <MainDataContainer>
          <MainDataSubContainer>
            <MainHeadingContainer>
              <div>
                <h1>Tests</h1>
              </div>
            </MainHeadingContainer>
            <MainTestsListContainer>
              {dashData.tests.map((test) => (
                <TestCard
                  key={test.UID}
                  editTest={editTest}
                  testData={test}
                ></TestCard>
              ))}
            </MainTestsListContainer>
          </MainDataSubContainer>
          <MainDataSubContainer>
            <MainHeadingContainer>
              <div>
                <h1>Users</h1>
              </div>
              <div>
                <Select
                  value={filterVal}
                  onChange={(e) => filterUsers(e.target.value)}
                >
                  <option value='ALL'>All users</option>
                  {dashData.batchList.map((batchID) => (
                    <option key={'BID' + batchID} value={batchID}>
                      {batchID}
                    </option>
                  ))}
                </Select>
              </div>
              {selectionMode ? (
                <UserMainBtnContainer>
                  <FaSave
                    onClick={() => {
                      editSelection();
                    }}
                    size='2rem'
                    cursor='pointer'
                  ></FaSave>
                  <FaWindowClose
                    onClick={() => {
                      closeSelection();
                    }}
                    size='2rem'
                    cursor='pointer'
                  ></FaWindowClose>
                </UserMainBtnContainer>
              ) : (
                <UserMainBtnContainer>
                  <FaEdit
                    onClick={() => {
                      batchEdit();
                    }}
                    size='2rem'
                    cursor='pointer'
                  ></FaEdit>
                  <FaUserPlus
                    size='2rem'
                    onClick={() => bulkAddUsers()}
                    cursor='pointer'
                  ></FaUserPlus>
                </UserMainBtnContainer>
              )}
            </MainHeadingContainer>
            <MainTestsListContainer>
              {/* !Need to add content here */}
              {usersToShow.map((user) => (
                <UserCard
                  selectionMode={selectionMode}
                  userSelected={selectedUsers}
                  userData={user}
                  editUser={() => editSingleUser(user.id)}
                  key={user.id}
                  editUserSelection={(userID, action) =>
                    editUserSelection(user.id, action)
                  }
                ></UserCard>
              ))}
            </MainTestsListContainer>
          </MainDataSubContainer>
        </MainDataContainer>
      </div>
      {(() => {
        return pageLoading ? (
          <LoadingEliment message={loadingMessage}></LoadingEliment>
        ) : (
          ''
        );
      })()}
      {(() => {
        return showEditScreen ? (
          <EditScreen>
            <EditScreenMainContainer>
              <h2>{selectedUsers.length} users have been selected.</h2>
              <EditScreenInpuContainer>
                <p>
                  <b>Users State</b>
                </p>
                <SelectEditBulk
                  value={bulkUserState}
                  onChange={(e) => editBulkUserState(e.target.value)}
                >
                  <option value=''>User State</option>
                  <option value='CURRENT_STUDENT'>CURRENT STUDENT</option>
                  <option value='OLD_STUDENT'>OLD STUDENT</option>
                </SelectEditBulk>
                <p>
                  <b>Users Batch</b>
                </p>

                <SelectEditBulk
                  value={bulkUserBatch}
                  onChange={(e) => editBulkUserBatch(e.target.value)}
                >
                  <option value=''>User Batch</option>
                  {(() =>
                    dashData.batchList.map((batch) => (
                      <option value={batch} key={`BulkUserRdit${batch}`}>
                        {batch}
                      </option>
                    )))()}
                </SelectEditBulk>
              </EditScreenInpuContainer>
              <EditScreenBtnContainer>
                <EditScreenExitBtn onClick={() => closeBulkEditScreen()}>
                  Close
                </EditScreenExitBtn>
                <EditScreenSaveBtn onClick={() => saveBulkEdit()}>
                  Save
                </EditScreenSaveBtn>
              </EditScreenBtnContainer>
            </EditScreenMainContainer>
          </EditScreen>
        ) : (
          ''
        );
      })()}
      {(() => {
        return showSingleUserEditScreen ? (
          <EditScreen>
            <EditScreenMainContainer>
              <h2>Edit User</h2>
              <EditScreenInpuContainer>
                <p>
                  <b>User Name</b>
                </p>
                <Input
                  value={singleUserName}
                  onChange={(e) => editSingleUserName(e.target.value)}
                ></Input>
                <p>
                  <b>User Email</b>
                </p>
                <Input
                  value={singleUserEmail}
                  onChange={(e) => editSingleUserEmail(e.target.value)}
                ></Input>
                <p>
                  <b>User Password</b>
                </p>
                <Input
                  value={singleUserPassword}
                  onChange={(e) => editSingleUserPassword(e.target.value)}
                ></Input>
                <p>
                  <b>Users State</b>
                </p>
                <SelectEditBulk
                  value={singleUserState}
                  onChange={(e) => editSingleUserState(e.target.value)}
                >
                  <option value=''>User State</option>
                  <option value='CURRENT_STUDENT'>CURRENT STUDENT</option>
                  <option value='OLD_STUDENT'>OLD STUDENT</option>
                </SelectEditBulk>
                <p>
                  <b>Users Batch</b>
                </p>

                <SelectEditBulk
                  value={singleUserBatch}
                  onChange={(e) => editSingleUserBatch(e.target.value)}
                >
                  <option value=''>User Batch</option>
                  {(() =>
                    dashData.batchList.map((batch) => (
                      <option value={batch} key={`singleUserRdit${batch}`}>
                        {batch}
                      </option>
                    )))()}
                </SelectEditBulk>
              </EditScreenInpuContainer>
              <EditScreenBtnContainer>
                <EditScreenExitBtn onClick={() => closeEditSingleUser()}>
                  Close
                </EditScreenExitBtn>
                <EditScreenSaveBtn onClick={() => saveEditSingleUser()}>
                  Save
                </EditScreenSaveBtn>
              </EditScreenBtnContainer>
            </EditScreenMainContainer>
          </EditScreen>
        ) : (
          ''
        );
      })()}
      {(() => {
        return showAddUsersScreen ? (
          <AddUserScreen>
            {(() => {
              return showAddUserErrScreen ? (
                <AddUserScreenMainContainer>
                  <AddBulkUsersUploadScreenContainer>
                    <h2 style={{ marginBottom: '1rem' }}>
                      The Following users Email is alredy in the system{' '}
                    </h2>
                    <UserAddCongormation>
                      <table style={{ width: '100%', marginBottom: '1rem' }}>
                        <tbody>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Password</th>
                          </tr>
                          {errUsersList.map((user) => (
                            <tr key={user.name + user.email}>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td>{user.password}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </UserAddCongormation>
                  </AddBulkUsersUploadScreenContainer>
                  <EditScreenBtnContainerV2>
                    <EditScreenExitBtn onClick={() => closeUserErrScreen()}>
                      Close
                    </EditScreenExitBtn>
                  </EditScreenBtnContainerV2>
                </AddUserScreenMainContainer>
              ) : (
                <AddUserScreenMainContainer>
                  {(() => {
                    return !showAddUsersConformationScreen ? (
                      <AddBulkUsersUploadScreenContainer>
                        <h2 style={{ paddingBottom: '1rem' }}>
                          Upload csv or xlsx File
                        </h2>
                        <CsvFileUpload onClick={handleClick}>
                          <FaCloudUploadAlt size='10rem'></FaCloudUploadAlt>
                        </CsvFileUpload>
                        <input
                          type='file'
                          accept='.csv,.xlsx,.xls'
                          onChange={svgTemplateUpload}
                          style={{ display: 'none' }}
                          ref={hiddenFileInput}
                        />
                        <TemplateDownloadContainer>
                          <a href='https://drive.google.com/file/d/1hDLqkr2lPQ2SzWhB_vwAuoDCNaoDP6KW/view?usp=sharing'>
                            Download the Template File
                          </a>
                        </TemplateDownloadContainer>
                      </AddBulkUsersUploadScreenContainer>
                    ) : (
                      <UsersAddConformationContainer>
                        <h2 style={{ paddingBottom: '1rem' }}>
                          Uploaded User Data.
                        </h2>
                        <UserAddCongormation>
                          <table style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Password</th>
                              </tr>
                              {usersToAdd.map((user) => (
                                <tr key={user.name + user.email}>
                                  <td>{user.name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.password}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </UserAddCongormation>
                      </UsersAddConformationContainer>
                    );
                  })()}
                  <EditScreenBtnContainer>
                    <EditScreenExitBtn onClick={() => closeAddUserScreen()}>
                      Close
                    </EditScreenExitBtn>
                    <EditScreenSaveBtn onClick={() => saveBulkUsersData()}>
                      Save
                    </EditScreenSaveBtn>
                  </EditScreenBtnContainer>
                </AddUserScreenMainContainer>
              );
            })()}
          </AddUserScreen>
        ) : (
          ''
        );
      })()}
    </MainContainer>
  );
};

export default AdminPage;

/* 
    * TO DO

    [*] Loading animation when screen is loaded.
    [*] test component 
    [*] user component
    [*] user sort
    [*] Edit Single User
    [*] Edit Bulk Users
    [*] Edit Test 
    [ ] Adding users
*/
