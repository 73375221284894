import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Route, Redirect, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Logo from '../assets/images/V-ReapLogoAnimation.svg';
// import { ReactComponent as Logo } from '../assets/images/Untitled-1.svg';
import { FaEdit, FaEye } from 'react-icons/fa';

const Container = styled.div`
  width: 99%;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  border: 1px solid #ff6363;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: ${(props) =>
    props.s == 'NOT-ACTIVE' ? '#fff' : '#ff6363'};
  color: ${(props) => (props.s == 'NOT-ACTIVE' ? '#000' : '#fff')};
`;

const P = styled.p`
  font-size: 1rem;
  font-weight: 700;
`;

const H2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const MainContainer = styled.div`
  width: 80%;
`;

const EditBtnContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const TestCard = ({ editTest, testData, showTest }) => {
  const history = useHistory();
  // console.log(testData);
  return (
    <Container s={testData.state}>
      <MainContainer>
        <H2>{testData.name}</H2>
        <P>UID: {testData.UID}</P>
        <P>Num Of Tests Taken: {testData.totalTestsTaken}</P>
      </MainContainer>
      <EditBtnContainer>
        <FaEdit
          cursor='pointer'
          size='1.5rem'
          onClick={() => editTest(testData.UID)}
        ></FaEdit>
      </EditBtnContainer>
    </Container>
  );
};

export default TestCard;
