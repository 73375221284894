import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Route, Redirect, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Logo from '../assets/images/V-ReapLogoAnimation.svg';
// import { ReactComponent as Logo } from '../assets/images/Untitled-1.svg';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0008;
`;

const MainContainer = styled.div`
  width: 50%;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 1rem;
  z-index: 99999999999;
`;

const AnimatedLogo = styled.object`
  width: 30%;
`;

const LoadingEliment = ({ message }) => {
  const history = useHistory();

  return (
    <Container>
      <MainContainer>
        <AnimatedLogo type='image/svg+xml' data={Logo}></AnimatedLogo>
        <h1>{message}</h1>
      </MainContainer>
    </Container>
  );
};

export default LoadingEliment;
