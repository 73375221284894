import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from '../api/customAxios';

import serverConst from '../const';

import LoadingEliment from '../components/loadingPage';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import ProgressBar from '../components/progressComponent';

import ResultMain from '../components/results';

import CareerOptions from '../components/CareerOptions';

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffbd69;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: ${(props) => (props.showCOS ? 'hidden' : 'visible')};
`;

const Container = styled.div`
  width: 60%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #aaa;
  text-align: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const DashBtnContainer = styled.div`
  padding: 1rem;
  margin-top: 2rem;
`;

const TakeTestBtn = styled.button`
  padding: 1.2rem;
  width: 80%;
  margin: auto;
  background-color: #ff6363;
  color: #fff;
  border: 2px solid #ff6363;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  &:hover {
    color: #ff6363;
    background-color: #fff;
  }
`;

const ViewResultBtn = styled.button`
  padding: 1.2rem;
  width: 80%;
  display: block;
  margin: auto;
  background-color: #ffbd69;
  color: #fff;
  border: 2px solid #ffbd69;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  &:hover {
    color: #ffbd69;
    background-color: #fff;
  }
`;

const ViewResultBtnV2 = styled.button`
  padding: 1rem;
  width: 45%;
  display: block;
  margin: auto;
  background-color: ${(props) => props.bgColor};
  color: #fff;
  border: 2px solid ${(props) => props.bgColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  &:hover {
    color: ${(props) => props.bgColor};
    background-color: #fff;
  }
`;

const ViewBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TestContainer = styled.div`
  width: 70%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #aaa;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const CloseTestBtn = styled.button`
  padding: 0.6rem;
  width: 40%;
  background-color: #ff6363;
  color: #fff;
  border: 2px solid #ff6363;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  &:hover {
    color: #ff6363;
    background-color: #fff;
  }
`;

const NextQuestonBtn = styled.button`
  padding: 0.6rem;
  width: 40%;
  background-color: #ffbd69;
  color: #fff;
  border: 2px solid #ffbd69;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  &:hover {
    color: #ffbd69;
    background-color: #fff;
  }
`;

const BtnLoadingContainer = styled.div`
  padding: 0.5rem;
  width: 40%;
  background-color: #ffbd69;
  color: #fff;
  border: 2px solid #ffbd69;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
`;

const TestBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const TestInstructionsContainer = styled.div``;

const TestQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgressBarContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const QuestionContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-top: 1rem;
`;

const Option = styled.button`
  padding: 1rem;
  width: 80%;
  background-color: ${(props) => (props.selected ? '#1cef5b' : '#fff')};
  color: ${(props) => (props.selected ? '#000' : '#000')};
  border: 1px solid #000;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  margin: auto;
  margin-bottom: 1rem;
  &:hover {
    border: 1px solid #ffbd69;
    color: ${(props) => (props.selected ? '#000' : '##ffbd69')};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.i`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  padding: 0.1rem;
`;

const StudentPage = ({}) => {
  const [userData, editUserData] = useState({});
  const [showDash, editShowDash] = useState(true);
  const [showTest, editShowTest] = useState(false);
  const [showResults, editShowResults] = useState(false);
  const [loadingScreen, editLoadingScreen] = useState(false);

  const [testData, editTestData] = useState({});

  const [showTestMainScreen, editShowTestMainScreen] = useState(true);
  //   const [showTestQuestionScreen, editShowTestQuestionScreen] = useState(false);

  const [currentQuestion, editCurrentQuestion] = useState('');
  const [currentQuestionIndex, editCurrentQuestionIndex] = useState(-1);
  const [currentSelOption, editCurrentSelOption] = useState(-1);

  const [userAnswers, editUserAnswers] = useState([]);
  const [questionBank, editQuestionBank] = useState([]);

  const [btnLoading, editBtnLoading] = useState(false);

  const [resultData, editResultData] = useState({});

  const [showCOS, editShowCOS] = useState(false);

  const [loadingMessage, editLoadingMessage] = useState(
    'Loading page, Please wait.',
  );
  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = () => {
    axios
      .get(`${serverConst.IP}/users/getUserData`)
      .then((res) => {
        console.log(res.data.data);
        editUserData(res.data.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const getTestData = () => {
    axios
      .get(`${serverConst.IP}/users/getUserTest/XUGR`)
      .then((res) => {
        console.log(res);
        editTestData(res.data.data);
        editUserAnswers(res.data.data.answers);
        editQuestionBank(res.data.data.questions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const takeTest = () => {
    editLoadingMessage('Loading the Test, Please Wait');
    editLoadingScreen(true);
    getTestData();
    setTimeout(() => {
      editLoadingScreen(false);
      editLoadingMessage('Loading Page, Please Wait');
      editShowDash(false);
      editShowResults(false);
      editShowTest(true);
      editShowTestMainScreen(true);
    }, 0);
  };

  const closeTest = () => {
    editShowDash(true);
    editShowResults(false);
    editShowTest(false);
  };

  const startTest = () => {
    editShowDash(false);
    editShowResults(false);
    editShowTest(true);
    editShowTestMainScreen(false);
    if (questionBank.length > 0) {
      if (userAnswers.length == 0) {
        editCurrentQuestion(questionBank[0].question);
        editCurrentQuestionIndex(questionBank[0].index);
        editCurrentSelOption(-1);
      } else {
        if (userAnswers.length == questionBank.length) {
          let lastAnsIndex = 0;
          editUserAnswers([]);
          editCurrentQuestion(questionBank[lastAnsIndex].question);
          editCurrentQuestionIndex(questionBank[lastAnsIndex].index);
          editCurrentSelOption(-1);
        } else {
          let lastAnsIndex = userAnswers.length;
          editCurrentQuestion(questionBank[lastAnsIndex].question);
          editCurrentQuestionIndex(questionBank[lastAnsIndex].index);
          editCurrentSelOption(-1);
        }
      }
    } else {
      alert('Error, please try after some time.');
    }
  };

  const selectOption = (optionVal) => {
    editCurrentSelOption(optionVal);
  };

  const previousQuestion = () => {
    let currentIndex = currentQuestionIndex;
    console.log(currentIndex);
    editCurrentQuestion(questionBank[currentIndex - 1].question);
    editCurrentQuestionIndex(questionBank[currentIndex - 1].index);
    editCurrentSelOption(userAnswers[currentIndex - 1].option);
  };

  const nextQuestion = () => {
    if (currentSelOption > 0) {
      if (
        currentQuestionIndex >= userAnswers.length ||
        currentSelOption != userAnswers[currentQuestionIndex].option
      ) {
        editBtnLoading(true);
        console.log(currentQuestionIndex, currentSelOption);
        const DataToSend = {
          testUID: 'XUGR',
          questionIndex: currentQuestionIndex,
          optionSelected: currentSelOption,
        };
        axios
          .post(`${serverConst.IP}/users/submitAnswer`, DataToSend)
          .then((res) => {
            console.log(res);
            editUserAnswers(res.data.data.answers);
            setTimeout(() => {
              let currentIndex = currentQuestionIndex;
              editCurrentQuestion(questionBank[currentIndex + 1].question);
              editCurrentQuestionIndex(questionBank[currentIndex + 1].index);
              editCurrentSelOption(-1);
              editBtnLoading(false);
            }, 0);
          })
          .catch((err) => {
            console.log(err);
            alert('some error occored in submittig the answer.');
          });
      } else {
        let currentIndex = currentQuestionIndex;
        editCurrentQuestion(questionBank[currentIndex + 1].question);
        editCurrentQuestionIndex(questionBank[currentIndex + 1].index);
        editCurrentSelOption(
          currentIndex + 1 == userAnswers.length
            ? -1
            : userAnswers[currentIndex + 1].option,
        );
      }
    } else {
      alert('Please select an option before going to next question.');
    }
  };

  const submitTest = () => {
    console.log('this function will submit the test.');
    if (currentSelOption > 0) {
      const DataToSend = {
        testUID: 'XUGR',
        questionIndex: currentQuestionIndex,
        optionSelected: currentSelOption,
      };
      axios
        .post(`${serverConst.IP}/users/submitAnswer`, DataToSend)
        .then((res) => {
          console.log(res);
          let dataToSend = {
            testUID: 'XUGR',
          };
          axios
            .post(`${serverConst.IP}/users/submitTest`, dataToSend)
            .then((res) => {
              console.log(res.data);
              editResultData(res.data.data);
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          alert('some error occored in submittig the answer.');
        });
    } else {
      alert('please select an option.');
    }
  };

  const openResultScreen = () => {
    loadUserData();

    editLoadingMessage('Loading Results, Please Wait');
    editLoadingScreen(true);
    setTimeout(() => {
      editResultData(
        Object.entries(userData.testResponses[0].results).sort(
          (a, b) => b[1] - a[1],
        ),
      );
      editShowDash(false);
      editShowResults(true);
      editShowTest(false);
      editLoadingMessage('Loading User Data, Please Wait');
      editLoadingScreen(false);
    }, 500);
  };

  const closeReslutScreen = () => {
    editShowDash(true);
    editShowResults(false);
    editShowTest(false);
  };

  const showCareerOptionsScreen = () => {
    editShowCOS(true);
  };

  const closeCareerOptionsScreen = () => {
    editShowCOS(false);
  };

  return (
    <MainContainer showCOS={showCOS}>
      {(() => {
        return userData && showDash ? (
          <Container>
            <h2>Welcome {userData.name}</h2>
            <DashBtnContainer>
              <TakeTestBtn onClick={() => takeTest()}>Take Test</TakeTestBtn>
            </DashBtnContainer>
            {(() => {
              if (userData.testResponses && userData.testResponses.length > 0) {
                console.log(
                  Object.keys(userData.testResponses[0].results).length > 0,
                );
              }
              return userData.testResponses ? (
                userData.testResponses.length > 0 &&
                Object.keys(userData.testResponses[0].results).length > 0 ? (
                  <DashBtnContainer>
                    <ViewResultBtn onClick={() => openResultScreen()}>
                      View Results
                    </ViewResultBtn>
                  </DashBtnContainer>
                ) : (
                  ''
                )
              ) : (
                ''
              );
            })()}
          </Container>
        ) : showTest ? (
          <TestContainer>
            {(() => {
              return showTestMainScreen ? (
                <TestInstructionsContainer>
                  <h2>{testData && testData.name ? testData.name : ''}</h2>
                  <br />
                  <br />
                  <h4>Description</h4>
                  <br />
                  <p>
                    {testData && testData.description
                      ? testData.description
                      : ''}
                  </p>
                  <br />
                  <br />
                  <h4>Instructions</h4>
                  <br />
                  <p>
                    {testData && testData.instructions
                      ? testData.instructions
                      : ''}
                  </p>
                  <TestBtnContainer>
                    <CloseTestBtn onClick={() => closeTest()}>
                      Close
                    </CloseTestBtn>
                    <NextQuestonBtn onClick={() => startTest()}>
                      Start Test
                    </NextQuestonBtn>
                  </TestBtnContainer>
                </TestInstructionsContainer>
              ) : (
                <TestQuestionContainer>
                  <ProgressBarContainer>
                    <ProgressBar
                      val={currentQuestionIndex + 1}
                      totalVal={questionBank.length}
                    ></ProgressBar>
                  </ProgressBarContainer>
                  <QuestionContainer>
                    {currentQuestion ? currentQuestion : ''}
                  </QuestionContainer>
                  <OptionsContainer>
                    <Option
                      selected={currentSelOption == 1}
                      onClick={() => selectOption(1)}
                    >
                      Mostly Disagree
                    </Option>
                    <Option
                      selected={currentSelOption == 2}
                      onClick={() => selectOption(2)}
                    >
                      Slightly Disagree
                    </Option>
                    <Option
                      selected={currentSelOption == 3}
                      onClick={() => selectOption(3)}
                    >
                      Slightly Agree
                    </Option>
                    <Option
                      selected={currentSelOption == 4}
                      onClick={() => selectOption(4)}
                    >
                      Mostly Agree
                    </Option>
                  </OptionsContainer>
                  <TestBtnContainer>
                    {currentQuestionIndex == 0 ? (
                      <CloseTestBtn onClick={() => closeTest()}>
                        Close
                      </CloseTestBtn>
                    ) : (
                      <CloseTestBtn onClick={() => previousQuestion()}>
                        Previous
                      </CloseTestBtn>
                    )}
                    {btnLoading ? (
                      <BtnLoadingContainer>
                        <LoadingIcon
                          as={AiOutlineLoading3Quarters}
                        ></LoadingIcon>
                      </BtnLoadingContainer>
                    ) : currentQuestionIndex == questionBank.length - 1 ? (
                      <NextQuestonBtn onClick={() => submitTest()}>
                        Submit
                      </NextQuestonBtn>
                    ) : (
                      <NextQuestonBtn onClick={() => nextQuestion()}>
                        Next
                      </NextQuestonBtn>
                    )}
                  </TestBtnContainer>
                </TestQuestionContainer>
              );
            })()}
          </TestContainer>
        ) : showResults ? (
          <TestContainer>
            <ResultMain
              resultData={resultData}
              userName={userData.name}
            ></ResultMain>
            <ViewBtnContainer>
              <ViewResultBtnV2
                bgColor='#ffbd69'
                onClick={() => showCareerOptionsScreen()}
              >
                Show Careers
              </ViewResultBtnV2>
              <ViewResultBtnV2
                bgColor='#ff6969'
                onClick={() => closeReslutScreen()}
              >
                Close
              </ViewResultBtnV2>
            </ViewBtnContainer>
            {showCOS ? (
              <CareerOptions
                resultData={resultData}
                userName={userData.name}
                closeCareerOptions={() => closeCareerOptionsScreen()}
              ></CareerOptions>
            ) : (
              ''
            )}
          </TestContainer>
        ) : (
          ''
        );
      })()}
      {(() => {
        return loadingScreen ? (
          <LoadingEliment message={loadingMessage}></LoadingEliment>
        ) : (
          ''
        );
      })()}
    </MainContainer>
  );
};

export default StudentPage;
