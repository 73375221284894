import React, { useState, useEffect } from 'react';

import styled, { keyframes } from 'styled-components';

import c3 from 'c3';
import * as d3 from 'd3';
const ResultContainer = styled.div`
  text-align: center;
`;

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const Path = styled.div`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${dash} 5s linear infinite;
`;

const StrengthValueContainer = styled.div`
  width: 100%;
  background-color: #eee;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  border-radius: 1.5rem;
  border: 1px solid #000;
  stroke-dashoffset: 100;
  animation: ${dash} 5s linear infinite;
`;

const StrengthValueInnerContainer = styled.div`
  background-color: ${(props) => props.color};
  height: 2.5rem;
  border-radius: ${2.5 / 2}rem;
  width: ${(props) => props.w}%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
`;

const StrengthVal = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`;

const MainContainer = styled.div`
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`;

const GaugeContainer = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    width: 48%;
  }
`;

const TopStrengths = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const Sitem = styled.div`
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-weight: 600;
  color: #fff;
  background-color: #1f77b4;
`;
const ResultMain = ({ resultData, userName }) => {
  useEffect(() => {
    let tempGraphData = {
      data: {
        // iris data from R
        columns: [],
        type: 'pie',
        onmouseover: function (d, i) {
          console.log('onmouseover', d, i);
        },
        onmouseout: function (d, i) {
          console.log('onmouseout', d, i);
        },
      },
      pie: {
        label: {
          format: function (value, ratio, id) {
            return d3.format('')(value);
          },
        },
      },
      legend: {
        position: 'top',
      },
      color: {
        pattern: [
          '#aec7e8',
          '#ff7f0e',
          '#ffbb78',
          '#98df8a',
          '#ff9896',
          '#c5b0d5',
          '#c49c94',
          '#e377c2',
          '#f7b6d2',
          '#7f7f7f',
          '#c7c7c7',
          '#bcbd22',
          '#dbdb8d',
          '#9edae5',
        ],
      },
    };
    tempGraphData.data.columns.push([resultData[0][0], resultData[0][1]]);
    tempGraphData.data.columns.push([resultData[1][0], resultData[1][1]]);
    tempGraphData.data.columns.push([resultData[2][0], resultData[2][1]]);
    tempGraphData.data.columns.push([resultData[3][0], resultData[3][1]]);
    tempGraphData.data.columns.push([resultData[4][0], resultData[4][1]]);
    tempGraphData.data.columns.push([resultData[5][0], resultData[5][1]]);
    tempGraphData.data.columns.push([resultData[6][0], resultData[6][1]]);

    console.log(tempGraphData);
    c3.generate(tempGraphData);
  }, []);

  return (
    <ResultContainer>
      <h1>{userName}'s Results</h1>
      <br />
      <h3>Your Strengths</h3>
      <MainContainer>
        <div id='chart' />
      </MainContainer>
      <TopStrengths>
        <h3>Your Top 3 Strengths</h3>
        <Sitem>{resultData[0][0]}</Sitem>
        <Sitem>{resultData[1][0]}</Sitem>
        <Sitem>{resultData[2][0]}</Sitem>
      </TopStrengths>
    </ResultContainer>
  );
};

export default ResultMain;
