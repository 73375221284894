import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #0008;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: centers;
`;

const CareersContainer = styled.div`
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CloseCareerOptionsBtn = styled.button`
  padding: 1.2rem;
  width: 80%;
  margin: auto;
  background-color: #ff6363;
  color: #fff;
  border: 2px solid #ff6363;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;

  &:hover {
    color: #ff6363;
    background-color: #fff;
  }
`;

const CareerOptionsMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const CareerOption = styled.p`
  padding: 0.5rem;
  padding-left: 0;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
`;

const CareerOptionsContainer = styled.div`
  overflow-y: scroll;
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const CareerOptions = ({ resultData, userName, closeCareerOptions }) => {
  const allCareerOptions = {
    Linguistic: [
      'attorney',
      'comedian',
      'communications specialist',
      'curator',
      'editor in publishing',
      'historian',
      'journalist',
      'lawyer',
      'librarian',
      'marketing consultant',
      'newscaster',
      'poet',
      'politician',
      'songwriter ',
      'speech-pathologist',
      'talk-show host',
      'teacher',
      'language translator',
      'writer',
    ],
    LogicalMathematical: [
      'accountant',
      'auditor',
      'computer analyst',
      'computer technician',
      'computer programmer',
      'database designer',
      'detective',
      'economist',
      'engineer',
      'lawyer',
      'mathematician',
      'network analyst',
      'pharmacist',
      'physician',
      'physicist',
      'researcher',
      'scientist',
      'statistician',
      'bookkeeper',
    ],
    SpatialVisual: [
      '3D modeling & simulation',
      'architect',
      'artist',
      'computer programmer',
      'engineer',
      'film animator',
      'graphic artist',
      'interior decorator',
      'photographer',
      'mechanic',
      'navigator',
      'outdoor guide',
      'pilot',
      'sculptor',
      'strategic planner',
      'surveyor',
      'truck driver',
      'urban planner',
      'webmaster',
    ],
    BodilyKinesthetic: [
      'actor',
      'athlete',
      'carpenter',
      'computer games designer',
      'craftsperson',
      'dancer',
      'doctor of sports ',
      'firefighter',
      'forest ranger',
      'jeweler',
      'mechanic',
      'personal trainer',
      'Phys Ed teacher',
      'physical therapist',
      'recreation specialist',
      'surgeon',
      'yoga instructor',
    ],
    Musical: [
      'audiologist',
      'choir director',
      'disc jockey',
      'music conductor',
      'music camp counselor',
      'music comedy actor',
      'music critic ',
      'music lawyer',
      'music librarian',
      'music publisher',
      'music promotor',
      'music retailer',
      'music teacher',
      'music therapist',
      'musician',
      'orchestra director',
      'piano tuner',
      'recording engineer',
      'singer',
      'songwriter',
      'sound editor',
      'speech pathologist',
      'voice actor',
      'voice instructor',
    ],
    Interpersonal: [
      'actor',
      'administrator',
      'communications manager',
      'conflict resolution specialist',
      'cruise director',
      'customer service rep',
      'dental hygienist',
      'group mediator',
      'human resources manager',
      'marketing specialist',
      'nurse',
      'PeaceCorps',
      'politician',
      'psychologist',
      'religious leader',
      'salesperson',
      'social director',
      'social worker',
      'teacher',
      'trainer facilitator',
      'travel counselor',
      'waiter/waitress',
    ],
    Intrapersonal: [
      'actor',
      'artist',
      'career counselor',
      'consultant',
      'criminologist',
      'energy healer',
      'futurist or trend predictor',
      'intelligence officer',
      'personal counselor',
      'philosopher',
      'program planner',
      'entrepreneur',
      'psychic',
      'psychologist',
      'researcher',
      'small business owner',
      'spiritual counselor',
      'theologian',
      'therapist',
      'writer',
      'wellness counselor',
    ],
  };

  useEffect(() => {
    console.log(resultData);
  }, []);
  return (
    <MainContainer>
      <CareersContainer>
        <CareerOptionsContainer>
          {resultData.map((d, i) => {
            if (i < 3) {
              return (
                <CareerOptionsMain key={d[0]}>
                  <h3>{d[0]}</h3>
                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {allCareerOptions[d[0]].map((da) => (
                      <CareerOption key={d[0] + da}>{da},</CareerOption>
                    ))}
                  </div>
                </CareerOptionsMain>
              );
            }
          })}
        </CareerOptionsContainer>
        <CloseCareerOptionsBtn onClick={() => closeCareerOptions()}>
          Close
        </CloseCareerOptionsBtn>
      </CareersContainer>
    </MainContainer>
  );
};

export default CareerOptions;
