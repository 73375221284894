import axios from 'axios';

import serverConst from '../const';

const instance = axios.create({
  baseURL: `${serverConst.IP}`,
  headers: {
    authorization: '',
  },
});

instance.interceptors.request.use((config) => {
  const customConfig = config;
  customConfig.headers = {
    'Content-Type': 'application/json',
  };

  const token = localStorage.getItem('jwt_token');

  if (!Boolean(token)) {
    localStorage.clear();
    window.location.href = '/';
  }

  if (Boolean(token)) {
    customConfig.headers.authorization = token;
  }

  return customConfig;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    // console.log(err.response );
    if (err.response.status == 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(err);
  },
);

export default instance;
