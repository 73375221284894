import React, { Component, useEffect, useState } from 'react';

import { Route, Redirect, useHistory, Router } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  userType = 'ALL',

  ...rest
}) => {
  const userData = localStorage.getItem('userData');
  const userToken = localStorage.getItem('jwt_token');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userType == 'ALL' || userToken) {
          if (userType == 'ALL' || userData.role == userType) {
            return <Component></Component>;
          } else {
            return <Redirect to='/NoAccess' />;
          }
        } else {
          // return <Redirect to='/login' />;
          return '';
        }
      }}
    ></Route>
  );
};

export default PrivateRoute;
