const serverConst = {
  IP: 'http://139.59.21.24:4000',
  colors: {
    darkTheme: {
      backgroundColor: '#000000',
      cardColor: '#202040',
      mainColor: '#543864',
      secColor: '#ff6363',
      terColor: '#ffbd69',
    },
    lightTheme: {
      backgroundColor: '#ffffff',
      cardColor: '#ffffff',
      mainColor: '#543864',
      secColor: '#ff6363',
      terColor: '#ffbd69',
    },
  },
};

export default serverConst;
