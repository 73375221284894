import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Route, Redirect, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ReactComponent as Logo } from '../assets/images/Untitled-1.svg';
import TextField from '../components/inputTxt';

import axios from 'axios';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import serverConst from '../const.js';
const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #ff6363;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  padding: 2rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  margin: auto;
  box-shadow: 1px 1px 9px #555;
  border-radius: 8px;
  @media (max-width: 768px) {
    width: 80%;
  }
  text-align: center;
`;

const LogoMain = styled.img`
  width: 35%;
  margin: auto;
`;

const LoginBtn = styled.button`
  padding: 0.6rem;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: #ffbd69;
  color: #000;
  border: 3px solid #ffbd69;
  cursor: pointer;
  :hover {
    border: 3px solid #ffbd69;
    background-color: #fff;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.i`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  padding: 0.1rem;
`;
const Login_Page = () => {
  const history = useHistory();
  const [email, setEmail] = useState(['', false, 'Enter valid Email']);
  const [pass, setPass] = useState([
    '',
    false,
    'Your password must be longer than 8 characters',
  ]);
  const [alertShow, editAlertShow] = useState(false);
  const [error, editError] = useState(false);
  const [errorMsg, editErrorMsg] = useState({
    title: 'No error',
    message: 'No message',
  });

  const [loading, editLoading] = useState(false);

  const validateInput = () => {
    let isValid = true;
    const regX = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    );
    if (!regX.test(email[0])) {
      setEmail([email[0], true, email[2]]);
      isValid = false;
    }
    return isValid;
  };

  const Login = () => {
    if (!loading) {
      editLoading(true);
      const validData = validateInput();
      if (validData) {
        let dataToSend = {
          email: email[0],
          password: pass[0],
        };

        axios
          .post(`${serverConst.IP}/users/login`, dataToSend)
          .then((res) => {
            console.log(res);
            localStorage.setItem('jwt_token', res.data.data.jwt);
            localStorage.setItem(
              'role',
              JSON.stringify(res.data.data.userRole),
            );
            editAlertShow(true);
          })
          .catch((err) => {
            editError(true);
            editAlertShow(true);
            editErrorMsg({
              title: 'Error',
              message: err.response ? err.response.data.message : err.message,
            });
          });
      } else {
        editError(true);
        editAlertShow(true);
        editErrorMsg({
          title: 'Input Error',
          message:
            'Your input is not a valid input, please enter a valid input and try again.',
        });
      }
    }
  };

  const alertCancel = () => {
    editError(false);
    editAlertShow(false);
    editErrorMsg({
      title: 'Input Error',
      message:
        'Your input is not a valid input, please enter a valid input and try again.',
    });
    editLoading(false);
  };

  const alertConform = () => {
    editAlertShow(false);
    editLoading(false);
    history.push('/homePage');
  };

  return (
    <MainContainer>
      <Container>
        <LogoMain as={Logo}></LogoMain>
        <h2>Login Page</h2>
        <TextField label='Email' value={email} editVal={setEmail}></TextField>
        <TextField label='Password' value={pass} editVal={setPass}></TextField>
        <LoginBtn onClick={() => Login()}>
          {(() =>
            loading ? (
              <LoadingIcon as={AiOutlineLoading3Quarters}></LoadingIcon>
            ) : (
              'Login'
            ))()}
        </LoginBtn>
        {(() => {
          if (alertShow) {
            if (error) {
              return (
                <SweetAlert
                  error
                  title={errorMsg.title}
                  onConfirm={() => alertCancel()}
                  onCancel={() => alertCancel()}
                  btnSize='sm'
                >
                  {errorMsg.message}
                </SweetAlert>
              );
            } else {
              return (
                <SweetAlert
                  success
                  title='Login successful!'
                  onConfirm={() => alertConform()}
                  onCancel={() => alertConform()}
                  btnSize='sm'
                >
                  Login Successful, Redirecting you ....
                </SweetAlert>
              );
            }
          } else {
            return '';
          }
        })()}
      </Container>
    </MainContainer>
  );
};

export default Login_Page;
