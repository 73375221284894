import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PrivateRoute from './utils/privateRouter';

import Landing_Page from './pages/LandingPage';

import Login_Page from './pages/LoginPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Login_Page}></Route>
        <PrivateRoute
          path='/homePage'
          exact
          userType='ALL'
          component={Landing_Page}
        ></PrivateRoute>
        {/* 
        <PrivateRoute
          path='/Test/XUGR'
          exact
          component={TestXUGR_Page}
        ></PrivateRoute>

        <PrivateRoute
          path='/admin'
          exact
          userType='ADMIN'
          component={Admin_Page}
        ></PrivateRoute>

        <PrivateRoute
          path='/admin/addUsers'
          exact
          userType='ADMIN'
          component={AddUsers_Page}
        ></PrivateRoute>

        <PrivateRoute
          path='/admin/ViewUserScores/:userID'
          exact
          userType='ADMIN'
          component={ViewUserScores_Page}
        ></PrivateRoute>

        <Router path='/NoAccess' exact component={NoAccessError_Page}></Router> */}
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

/* 

! TO DO list

? Things to do before building the ui

* [*]  setup the router
* [*]  setup the api code
* [*]  setup the private routes


? Building the UI

* [ ] Login Page Route
* [ ] User Dashboard
* [ ] User Test Route
* [ ] User Test Results Route

* [ ] Admin Dashboard
* [ ] Admin Add Users Page
* [ ] Download DB option.


*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
