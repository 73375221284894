import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
`;

const BarContainer = styled.div`
  width: 80%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
`;

const TextContainer = styled.div`
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: #543864;
`;

const OuterBar = styled.div`
  width: 100%;
  border-radius: 1rem;
  background-color: #ccc;
  height: 2rem;
  padding: 0.3rem;
`;

const InnerBar = styled.div`
  width: ${(props) => props.v}%;
  border-radius: 1rem;
  height: 1.4rem;
  background-color: #543864;
  transition: all 0.3s;
`;

const ProgressBar = ({ val, totalVal }) => {
  return (
    <MainContainer>
      <BarContainer>
        <OuterBar>
          <InnerBar v={(val / totalVal) * 100}></InnerBar>
        </OuterBar>
      </BarContainer>
      <TextContainer>
        <div>
          <b>{val}</b> / <b>{totalVal}</b>
        </div>
      </TextContainer>
    </MainContainer>
  );
};

export default ProgressBar;
