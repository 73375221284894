import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Route, Redirect, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Logo from '../assets/images/V-ReapLogoAnimation.svg';
// import { ReactComponent as Logo } from '../assets/images/Untitled-1.svg';
import { FaEdit, FaEye } from 'react-icons/fa';

const Container = styled.div`
  width: 99%;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  border: 1px solid #ff6363;
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #000;
`;

const P = styled.p`
  font-size: 1rem;
  font-weight: 700;
`;

const H2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const MainContainer = styled.div`
  width: 80%;
`;

const EditBtnContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const UserCard = ({
  editUser,
  userData,
  userSelected,
  selectionMode,
  editUserSelection,
}) => {
  const [checked, setchecked] = useState(
    userSelected.indexOf(userData.id) != -1,
  );
  const history = useHistory();

  useEffect(() => {
    setchecked(userSelected.indexOf(userData.id) != -1);
  }, [userSelected]);

  const selectUser = () => {
    console.log('PP');
    setchecked(!checked);
    if (checked) {
      editUserSelection(userData.id, 'REMOVE');
    } else {
      editUserSelection(userData.id, 'ADD');
    }
  };

  return (
    <Container>
      <MainContainer>
        <H2>{userData.name}</H2>
        <P>Batch Number: {userData.batchNo}</P>
        <P>Status: {userData.status}</P>
      </MainContainer>
      <EditBtnContainer>
        {(() =>
          selectionMode ? (
            <input
              name='isSelected'
              type='checkbox'
              checked={checked}
              onChange={() => selectUser()}
            />
          ) : (
            <FaEdit
              cursor='pointer'
              size='1.5rem'
              onClick={() => editUser()}
            ></FaEdit>
          ))()}
      </EditBtnContainer>
    </Container>
  );
};

export default UserCard;
