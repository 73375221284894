import { React, useState } from 'react';

import styled from 'styled-components';

import serverConst from '../const.js';

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
  text-align: left;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
  color: #000;
  display: ${(props) => (props.focused ? 'block' : 'none')};
`;

const Input = styled.input`
  padding: 0.7rem;
  border-radius: 7px;
  border: 1px solid ${(props) => (!props.error ? 'black' : 'red')};
  &.dark {
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
  }
`;

const ErrorMsg = styled.p`
  font-size: 0.8rem;
  margin: 0;
  color: red;
  margin-top: 0.2rem;
  margin-left: 0.3rem;
`;

const TextField = ({ label, value, editVal }) => {
  const [focused, setFocused] = useState(false);

  //   console.log(value);
  return (
    <InputField>
      <Label focused={focused}>{label}:</Label>
      <Input
        focused={focused}
        type='text'
        value={value[0]}
        onChange={(e) => editVal([e.target.value, false, value[2]])}
        placeholder={label}
        error={value[1]}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
        }}
      />
      {value[1] ? <ErrorMsg>{value[2]}</ErrorMsg> : ''}
    </InputField>
  );
};

export default TextField;
